<template>
  <div class="sgroup">
    <div class="searchw">
      <Form inline :label-width="52">
        <FormItem label="角色名" :label-width="53" style="width: 150px">
          <Input maxlength="32" v-model="posdata.title" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="" :label-width="0">
          <Button type="default" icon="ios-search" @click="search" @keyup.13="search">搜索</Button>
        </FormItem>
        <Button style="float: right;" type="info" icon="md-add" @click="add_group">添加角色</Button>
      </Form>
    </div>
    <Table size="small" :columns="columns" :data="data.data" :loading="table_loading">
      <template slot-scope="row" slot="action">
        <template v-if="row.row.master==0">
          <Button type="info" size="small" @click="edit_group(row.index)">修改名称</Button>
          <Button style="margin-left: 10px;" type="primary" size="small" @click="showPower(row.row.id)">修改权限</Button>
          <Button style="margin-left: 10px;" type="error" size="small" @click="del_group(row.row.id)">删除角色</Button>
        </template>
        <span v-else>--</span>
      </template>
    </Table>
    <div class="footer-m">
      <Page class-name="pagew page-r" :total="data.total" :current="data.current_page" :page-size="data.per_page" :page-size-opts="[15,30,50]" show-sizer show-total @on-change="page" @on-page-size-change="change_pagesize" />
    </div>
    <Modal v-model="addg.status" width="300" title="设置角色名称" :loading="addg.loading" @on-ok="save_group" @on-visible-change="closeaddg">
      <Input v-model="addg.data.title" autofocus type="text" maxlength="15" placeholder="请输入角色名称" />
    </Modal>
    <edit-power ref="editPower"></edit-power>
  </div>
</template>
<script>
import editPower from "@/views/power/edit_power"
export default {
  name: 'SuperGroup',
  components:{editPower},
  data(){
    return {
      columns:[
        {title: '角色ID',key: 'id',maxWidth:300},
        {title: '角色名',key: 'title'},
        {title: '操作',slot:'action',width:260,align:'center'},
      ],
      addg:{
        data:{id:'',title:''},
        status:false,
        loading:true,
      },
      data:{},
      table_loading:false,
      posdata:{
        title:'',
        page:1,
        page_size:15
      }
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.table_loading=true;
      this.requestApi('/adm/super_group.html',this.posdata).then(function (res){
        _this.table_loading=false;
        if(res.status==1) _this.data=res.data;
      })
    },
    /**
     * 添加角色-弹出
     */
    add_group:function (){
      this.addg.status=true;
      this.addg.data.id=0;
    },
    /**
     * 修改角色名称
     */
    edit_group:function (key){
      let data=JSON.parse(JSON.stringify(this.data.data[key]));
      this.addg.data={'id':data.id,'title':data.title};
      this.addg.status=true;
    },
    /**
     * 保存角色
     */
    save_group:function (){
      var _this=this;
      this.addg.data.title=this.addg.data.title.trim();
      if(this.addg.data.title==''){
        this.addg.loading = false;
        setTimeout(function (){_this.addg.loading = true;},5);
        return this.alertError('请填写角色名称！');
      }
      this.requestApi('/adm/save_group.html',this.addg.data).then(function (res){
        if(res.status==1){
          _this.init();
          _this.addg.status =false;
          _this.tipSuccess(res.msg);
        }else{
          _this.addg.loading = false;
          setTimeout(function (){_this.addg.loading = true;},5);
          _this.alertError(res.msg);
        }
      })
    },
    /**
     * 删除角色
     */
    del_group:function (id){
      var _this=this;
      this.confirm('确定要删除该角色吗？',function (){
        _this.requestApi('/adm/del_group.html', {'id':id}).then(function (res){
          if(res.status==1){
            _this.init();
            _this.tipSuccess(res.msg);
          }else{
            _this.alertError(res.msg);
          }
        })
      })
    },
    /**
     * 关闭角色弹出
     */
    closeaddg:function (status){
      if(!status){
        this.addg={
          data:{id:'',title:''},
          status:false,
          loading:true
        }
      }
    },
    /**
     * 打开权限弹出
     */
    showPower:function (id){
      this.$refs.editPower.open('group',id);
    },
    /**
     * 搜索
     */
    search:function (){
      this.posdata.page=1;
      this.init();
    },
    /**
     * 翻页
     */
    page:function (page){
      this.posdata.page=page;
      this.init();
    },
    /**
     * 切换分页条数
     */
    change_pagesize:function (size){
      this.posdata.page_size=size;
      if(this.posdata.page==1) this.init();
    }
  }
}
</script>
<style lang="less">
.nouse{}
</style>